'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.users.controller:UserGroupMemberCtrl

 # @description

###
class UserGroupMemberCtrl
  ### @ngInject ###
  constructor:(
    id
    $mdDialog
    UserGroupManager
    UserContextManager
    $translate
    _
    $q
    $log
  ) ->
    @progress = true
    @model = {}
    @errors = []
    @entity = {}

    init = =>
      @id = id
      @items = []


      @load = load
      @addUser = addUser
      @removeMember = removeMember
      @cancel = cancel
      @submit = submit
      @getNewForm = getNewForm

      @load()

    ##########

    getNewForm = (items) ->
      userContexts = []
      UserContextManager.getFullList()
      .then((list)->
        for item in list
          if !(item.id in items) and !item.isGroup
            item.label = item.user.username
            userContexts.push item

        userContexts.sort (a, b) ->
          a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      )
      [
        key: 'label'
        name: 'userContext'
        type: 'select'
        templateOptions:
          label: 'User context'
          placeholder: 'User context'
          required: true
          labelProp: "label"
          valueProp: "label"
          options: userContexts
      ]

    addUser = ->
      @progress = true

      UserContextManager
      .getFullList
        'filter[]': "OR,user.username,EQ #{@model.label},user.email,EQ #{@model.label}"
        'limit': 1
      .then (results) =>
        if results? and results.length
          result = results[0]
          exists = (@entity.userContexts.filter (v) -> v.id == result.id).length

          if exists
            @errors.push
              key: $translate.instant 'app.errors.user-already-member'
              value: [result.user.username]
          else
            groups = result.userContextGroups.map (v) -> v.id
            groups.push @entity.id

            UserGroupManager
            .transport
            .all 'user_contexts'
            .one result.id
            .patch
              userContextGroups: groups
            .then () =>
              @model = {}
              @load()

            , (error) ->
              for key, value of error.data.errors.children
                if value.errors?
                  @errors.push
                    key: key
                    value: value.errors
        else
          @errors.push
            key: $translate.instant 'app.errors.could-not-find-user'
            value: [@model.label]

      , (error) ->
        @errors.push
          key: $translate.instant 'app.errors.could-not-find-user'
          value: [@model.label]

    removeMember = (memberLabel) ->
      @progress = true
      ## Start remove

      UserContextManager
      .getFullList
        'filter[]': "OR,user.username,EQ #{memberLabel},user.email,EQ #{memberLabel}"
        'limit': 1
      .then (results) =>
        if results? and results.length
          result = results[0]

          exists = (@entity.userContexts.filter (v) -> v.id == result.id).length

          if exists

            groups = result.userContextGroups.map (v) -> v.id
            groups.splice groups.indexOf(@entity.id), 1

            UserGroupManager
            .transport
            .all 'user_contexts'
            .one result.id
            .patch
              userContextGroups: groups
            .then () =>
              @model = {}
              @load()

            , (error) ->
              for key, value of error.data.errors.children
                if value.errors?
                  @errors.push
                    key: key
                    value: value.errors

          else
            @errors.push
              key: $translate.instant 'app.errors.user-not-member'
              value: [result.user.username]

        else
          @errors.push
            key: $translate.instant 'app.errors.could-not-find-user'
            value: [label]

      , (error) ->
        @errors.push
          key: $translate.instant 'app.errors.could-not-find-user'
          value: [label]
      ## Stop remove

      @load()

    load = ->
      @fields = []
      UserGroupManager.one(@id)
        .then (data) =>
          @userGroupLabel = data.label
          @entity = data
          @items = []
          ids = []
          for userContext in data.userContexts
            if !userContext.user.deletedAt and !userContext.isGroup
              userContext.label = userContext.user.username
              @items.push(userContext)
            ids.push userContext.id
          @fields = @getNewForm(ids)
          @progress = false
          @form = {}
          @errors = []

    cancel = ->
      $mdDialog.cancel()

    submit = ->
      console.log 'submit'


    init()
angular
  .module('mundoAdmin.users')
  .controller 'UserGroupMemberCtrl', UserGroupMemberCtrl
